/* Styling for the PDF pages */
.pdf-page {
    width: 595.28pt; /* A4 width in points */
    height: 841.89pt; /* A4 height in points */
    border: 6px double #333; /* Double border */
    padding: 20pt;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #ffffff;
    page-break-after: always; /* Ensure each page is treated separately */
}

/* General details page styling */
.details-page-container {
    padding: 40px;
    background-color: #e5e5d2;
    border-radius: 8px;
    max-width: 100%;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.details-content {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
    color: #333;
    text-align: left;
    max-width: 800px;
    margin: auto;
    white-space: pre-wrap;
}

.back-button, .download-button {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 16px;
    color: #1a1919;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 10px;
    transition: transform 0.2s ease;
    display: inline-flex;
    align-items: center;
}

.back-button:hover, .download-button:hover {
    transform: scale(1.05);
}
