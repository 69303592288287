.carousel-container {
    position: relative;
    width: 100%; /* Use full viewport width */
    height: 400px; /* Height of the carousel */
    overflow: hidden;
    margin: auto; /* Center the carousel */
}

.carousel-wrapper {
    display: flex;
    width: 300%; /* Three images side by side */
    transition: transform 0.5s ease-in-out; /* Transition for the sliding effect */
    transform: translateX(0); /* Initial position */
}

.carousel-image {
    width: 33.33%; /* Each image takes one-third of the width */
    height: 100%; /* Full height */
    object-fit: cover; /* Cover the entire area */
}



.slide-in-left {
    transform: translateX(-100%); /* Slide in from the left */
}

.slide-out-right {
    transform: translateX(100%); /* Slide out to the right */
}

.slide-in-right {
    transform: translateX(100%); /* Slide in from the right */
}

.slide-out-left {
    transform: translateX(-100%); /* Slide out to the left */
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
}

.carousel-button.prev {
    left: 10px;
}

.carousel-button.next {
    right: 10px;
}
.carousel-container {
    position: relative;
    width: 100%; /* Full width */
    height: 100%; /* Height of the carousel */
    overflow: hidden;
    margin: auto; /* Center the carousel */
}

.carousel-wrapper {
    display: flex;
    width: 300%; /* Three images side by side */
    transition: transform 0.5s ease-in-out; /* Transition for the sliding effect */
    transform: translateX(0); /* Initial position */
}

.carousel-image {
    width: 33.33%; /* Each image takes one-third of the width */
    height: 100%; /* Full height */
    object-fit: cover; /* Cover the entire area */
}


.slide-in-left {
    transform: translateX(-100%); /* Slide in from the left */
}

.slide-out-right {
    transform: translateX(100%); /* Slide out to the right */
}

.slide-in-right {
    transform: translateX(100%); /* Slide in from the right */
}

.slide-out-left {
    transform: translateX(-100%); /* Slide out to the left */
}

/* Styling for the fixed image */
.fixed-image-container {
    position: absolute;
    top: 48%;
    right: 23%;
    transform: translateY(-50%);
    z-index: 2; /* Ensure the fixed image is above the carousel */
    width: 25%; /* Fixed image width */
    height: auto; /* Maintain aspect ratio */
}

.fixed-image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../Certificates/Certificate.png'); /* Path to the background image */
    background-size: cover;
   
}

.fixed-image {
    width: 100%; /* Make the fixed image fill the container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Mobile styles */
@media (max-width: 768px) {
    .carousel-container {
       margin-top: 1%;
        height: 100%; /* Reduced height for mobile */
    }

    .carousel-image {
        width: 35%; /* Each image takes full width on mobile */
    }

    .carousel-wrapper {
        width: 300%; /* Still maintain three images, but only one shows */
        transform: translateX(0);
    }

    .slide-in-left,
    .slide-out-right,
    .slide-in-right,
    .slide-out-left {
        transform: translateX(0); /* Reset for mobile view */
    }
    .fixed-image-container {
        position: relative;
        top: 50px;
        right: -20%;
        transform: translateY(-50%);
        z-index: 2; /* Ensure the fixed image is above the carousel */
        width: 60%; /* Fixed image width */
        height: auto; /* Maintain aspect ratio */
    }

}
