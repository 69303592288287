.gallery-container {
  background-color: #f9f9f9;
  padding: 0; /* Remove padding from container */
}

.gallery-title {
  text-align: left;
  font-size: 28px;
  margin-bottom: 0;
  font-weight: bold;
  color: #333;
}

.gallery-section {
  margin-bottom: 20px; /* Reduced space between sections */
}

.gallery-subtitle {
  text-align: center;
  font-size: 24px;
  margin: 10px 0; /* Reduced margin */
  color: #1a1919;
  font-weight: bold;
}

/* Masonry Layout */
.gallery-flex-container {
  column-count: 4; /* Adjust the number of columns */
  column-gap: 0.8%; /* Space between columns */
  margin: 2%;
}

.gallery-item {
  break-inside: avoid; /* Prevent items from being split between columns */
  margin-bottom: 10px; /* Space between rows of items */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.gallery-item img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the area without distortion */
  border-radius: 8px;
}

.gallery-item:hover {
  transform: scale(1.02);
}

/* Modal styles */
.modal-gallary {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is on top */
}

.modal-content-gallary {
  position: fixed;
  max-width: 90vw;
  max-height: 90vh;
  padding: 0; /* Removed padding */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image-gallary {
  max-width: 60%;
  max-height: auto;
  object-fit: contain; /* Ensure the image maintains aspect ratio */
  border-radius: 8px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  z-index: 1010; /* Higher than modal image */
}

.close:hover {
  color: red;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .gallery-title {
    text-align: left;
    font-size: 20px;
    margin-bottom: 0;
    font-weight: bold;
    color: #333;
  }
  
  .gallery-subtitle {
    text-align: center;
    font-size: 18px;
    margin: 10px 0; /* Reduced margin */
    color: #1a1919;
    font-weight: bold;
  }
  .gallery-flex-container {
    column-count: 2; /* Reduce columns for tablets */
  }

  .modal-image-gallary {
    max-width: 100%;
    max-height: auto;
    object-fit: contain; /* Ensure the image maintains aspect ratio */
    border-radius: 8px;
  }
}

@media (max-width: 480px) {
  .gallery-flex-container {
    column-count: 2; /* Single column for mobile devices */
  }
}
