/* General styles for the About component */
.about-container {
    background-color: #f3e5ab; /* Warm cream shade */
    padding: 40px;
    font-family: 'Arial', sans-serif;
    color: #333;
    width: 100%;
    margin: 0;
    box-sizing: border-box; /* Ensures padding doesn't overflow */
}

/* Section styles */
.section {
    background-color: #f3e5ab;
    padding: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Image styles */
.section-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

/* Text content styles */
.text-content {
    max-width: 80%;
    margin: 0;
    text-align: left;
}

/* Headings and Paragraphs */
h2 {
    font-size: 1.8rem;
    color: #444;
    margin-bottom: 10px;
    position: relative;
}

/* Underline for h2 headings */
h2::after {
    content: '';
    display: block;
    width: 550px;
    height: 4px;
    background-color: #b8860b;
    margin-top: 5px;
}

/* Center-aligned underline for h1 */
h1::after {
    content: '';
    display: block;
    width: 200px;
    height: 4px;
    background-color: #b8860b;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
}

/* Paragraph styling */
p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
}

/* Section-specific headings */
.known-for-heading, 
.tourist-love-heading, 
.contact-us-heading, 
.vision-heading, 
.follow-us-heading {
    font-size: 2rem;
    color: #444;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
}

.about-us-heading {
    font-size: 2rem;
    color: #444;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
}

.tourist-love-subheading {
    font-size: 1.2rem;
    color: #666;
    font-style: italic;
    margin-bottom: 20px;
    text-align: left;
}

/* Contact Us section */
.contact-us-section {
    margin-top: 40px;
}

.contact-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.contact-icon {
    margin-right: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .about-container {
        padding: 20px;
    }

    h2::after {
        width: 100%; /* Adjust underline width */
    }

    h1::after {
        width: 50%; /* Shorter underline for smaller screens */
    }

    .section-image {
        width: 150px;
        height: 150px;
        margin-bottom: 10px;
    }

    .text-content {
        max-width: 100%; /* Allow full width on smaller screens */
    }

    h2 {
        font-size: 1.5rem;
    }

    .about-us-heading {
        font-size: 1.8rem;
    }

    p {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .section {
        align-items: center; /* Center-align on very small screens */
    }

    .section-image {
        width: 120px;
        height: 120px;
    }

    .contact-details {
        align-items: center; /* Center-align contact items */
    }

    .known-for-heading, 
    .tourist-love-heading, 
    .contact-us-heading, 
    .vision-heading, 
    .follow-us-heading {
        font-size: 1.5rem; /* Reduce heading size */
    }

    .tourist-love-subheading {
        font-size: 1rem;
    }
}
