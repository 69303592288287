/* src/Component/Honeymoon.css */

.category-details-container {
    padding: 20px; /* Add some padding around the details section */
    background-color: #e5e5d2; /* Match the background color */
  }
  
  .categories-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Grid setup */
    gap: 30px; /* Space between grid items */
    margin-bottom: 20px;
    margin-top: 40px
  }
  
  .category-item {
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    cursor: pointer;
    background-color: #ffffff;
    padding: 10px; /* Add padding inside each item */
  }
  
  .category-image {
    width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px;
  }
  
  .image-name {
    margin-top: 10px; /* Space above the name */
    font-size: 22px; /* Adjust font size */
    color: #1a1919; /* Text color */
    font-weight: bold;
    margin-bottom: 5px
  }
  
  .category-item:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }


.back-button {
  background-color: transparent; /* Make the button background transparent */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  background-color: #ffffff;
  padding: 4px;
  text-align: center;
  font-weight: bold;
  font-size: 18px; /* Increase font size */
  color: #1a1919; /* Set text color */
  cursor: pointer; /* Change cursor to pointer */
  margin-top: 20px; /* Space below the button */
}
.back-button:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}


.back-button i {
  margin-right: 5px; /* Space between icon and text */
}
/* Media query for mobile devices */
@media (max-width: 600px) {
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 30px;
  }
  


.category-item {
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
  background-color: #ffffff;
}

.category-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.category-title {
  margin-top: 10px;
  font-size: 20px;
  color: #1a1919;
  font-weight: bold;
  margin-bottom: 10px;
}

.category-item:hover {
  transform: scale(1.08);
}

.back-button {
 
  margin-bottom: 35%; /* Space below the button */
}
.back-button:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}


.back-button i {
  margin-right: 5px; /* Space between icon and text */
}
}