.categories-container {
  padding: 20px;
  background-color: #e5e5d2;
}

.categories-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 30px;
  }
  
}

.category-item {
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  cursor: pointer;
  background-color: #ffffff;
}

.category-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.category-title {
  margin-top: 10px;
  font-size: 25px;
  color: #1a1919;
  font-weight: bold;
  margin-bottom: 20px;
}

.category-item:hover {
  transform: scale(1.08);
}