/* Stop background scrolling when sidebar is open */
.no-scroll {
    overflow: hidden;
}

/* Sidebar overlay styling */
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001; /* Ensure it overlaps the header strip */
}

/* Sidebar content styling */
.sidebar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px; /* Fixed width for the sidebar */
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 1002; /* Ensure it overlaps the header strip */
    overflow-y: auto;
    transition: transform 0.3s ease; /* Smooth open/close animation */
    transform: translateX(-100%); /* Initially hidden */
}

/* Show the sidebar when open */
.sidebar-overlay .sidebar-content {
    transform: translateX(0);
}

/* Header strip styles */
.header-strip {
    background-color: rgba(245, 243, 229, 0.475); /* Soft cream background */
    padding: 0.2% 0;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure it's below the sidebar */
    backdrop-filter: blur(5px);
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between hamburger and nav */
}

/* Hamburger button styles */
.sidebar-toggle {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 15px;
    z-index: 1002; /* Ensure it overlaps the header */
    display: flex;
    align-items: center;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 25px;
    height: 25px;
}

.hamburger span {
    display: block;
    width: 100%;
    height: 4px;
    background-color: #000;
    transition: all 0.3s ease;
}

/* Transform lines into a cross when open */
.hamburger.open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

/* Navigation list styling */
.nav-list-headerstrip {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 4px;
}

.nav-list-headerstrip li {
    margin-left: 12px;
}

.nav-list-headerstrip li a {
    text-decoration: none;
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    padding: 4px 10px;
    font-family: 'Verdana', sans-serif;
    transition: color 0.3s, background-color 0.3s, text-decoration 0.3s;
}

.nav-list-headerstrip li a:hover {
    text-decoration: underline;
}

/* Adjust header for smaller screens */
@media (max-width: 768px) {
    .header-strip {
        justify-content: flex-start;
    }

    .nav-list-headerstrip {
        display: none; /* Hide desktop nav */
    }
}
