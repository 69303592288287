.query-container {
    padding: 20px;
    background-color: #e5e5d2;
    max-width: 600px;
    margin: auto;
    margin-bottom: 2% ;
    margin-top: 2%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .query-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .query-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px; /* Added padding for better appearance */
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .query-input, .query-textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
  }
  
  .query-textarea {
    height: 100px;
    resize: none;
  }
  
  .query-button {
    background-color: #1a1919;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .query-button:hover {
    background-color: #333333;
  }
  