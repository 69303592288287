.team-container {
  padding: 20px;
  background-color: #f5f5dc;
}

.team-title {
  text-align: left;
  font-size: 28px;
  margin-bottom: 0;
  font-weight: bold;
  color: #333;
}

.team-section {
  margin-bottom: 40px;
}

.team-subtitle {
  text-align: center;
  font-size: 24px;
  margin: 20px 0 10px;
  color: #1a1919;
  font-weight: bold;
}

h5::after {
  content: '';
  display: block;
  width: 300px; /* Width of the underline */
  height: 4px; /* Thickness of the underline */
  background-color: #b8860b; /* Dark golden color for the underline */
  margin-top: 5px; /* Space between heading and underline */
  margin-left: 600px;
  margin-bottom: 15px;
}

/* Flexbox Layout */
.team-grid {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  justify-content: center; /* Center the items */
  gap: 35px; 
  margin-top: 40px;
}

.team-item {
  flex: 0 0 calc((100% / 7) - 20px); /* Width calculation based on column count */
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.team-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.team-item:hover {
  transform: scale(1.05);
}



/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is on top */
}

.modal-content {
  position: fixed;
  max-width: 90vw;
  max-height: 90vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 30%;
  max-height: 30%;
  object-fit: contain; /* Ensure the image maintains aspect ratio */
  border-radius: 8px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  z-index: 1010; /* Higher than modal image */
}

.close:hover {
  color: red;
}
/* Responsive Design */
@media (max-width: 768px) {
  
  .team-item {
      flex: 0 0 calc((100% / 2) - 20px); /* 2 columns */
  }
  .modal-image {
    max-width: 70%; /* Increase width for mobile */
    max-height: 70%;
  }
}

@media (max-width: 480px) {
  .team-item {
      flex: 0 0 calc((100% / 5) - 20px); /* 2 column */
  }
  .modal-image {
    max-width: 70%; /* Increase width for mobile */
    max-height: 70%;
  }
}