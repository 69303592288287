.catalogue-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  background-color: #f5f5dc; /* Dark cream background color */
  min-height: 100%; /* Full height of the viewport */
  padding: 20px;
  text-align: center; /* Center text alignment */
}

/* Slideshow container styles */
.slideshow-container {
  width: 60%; /* Adjust width for desktop */
  position: relative;
}

.slideshow-item {
  cursor: pointer; /* Change cursor on hover */
}

.slideshow-image {
  width: 100%; /* Full width for responsiveness */
  height: 500px; /* Maintain consistent height */
  object-fit: cover; /* Maintain aspect ratio */
}

/* Thumbnail container styles */
.thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap; /* Allow wrapping for thumbnails on smaller screens */
}

.thumbnail-item {
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.thumbnail-item:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

.thumbnail-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border: 2px solid transparent;
  border-radius: 5px; /* Slightly rounded corners */
}

.thumbnail-item.active .thumbnail-image {
  border-color: #007bff; /* Highlight active thumbnail */
}

/* Dots container styles */
.dot-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #bbb;
  cursor: pointer;
}

.dot.active {
  background-color: #007bff; /* Highlight active dot */
}

/* Title styles */
.catalogue-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Mobile adjustments */
@media (max-width: 932px) {
  .slideshow-container {
    width: 100%; /* Full width on smaller screens */
  }

  .slideshow-image {
    height: 480px; /* Adjust height for smaller screens */

  }

  .thumbnail-container {
    margin: 10px 0; /* Reduce spacing */
  }

  .thumbnail-image {
    width: 40px;
    height: 40px; /* Smaller thumbnails */
  }

  .catalogue-title {
    font-size: 1.5rem; /* Adjust title size */
  }
}

@media (max-width: 480px) {
  .slideshow-image {
    height: 200px; /* Further reduce height for mobile */
  }

  .thumbnail-image {
    width: 30px;
    height: 30px; /* Smaller thumbnails for compact screens */
  }

  .dot {
    height: 8px;
    width: 8px; /* Smaller dots */
  }

  .catalogue-title {
    font-size: 1.2rem; /* Smaller title font */
  }
}
