/* General Header Styles */
.header {
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 1000;
  width: 100%; /* Ensures it doesn't overflow horizontally */
  max-width: 1200px;
}

/* Header Content Styling */
.header-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
}

/* Desktop Navigation */
.desktop-nav {
  display: flex;
}

.desktop-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.desktop-nav ul li {
  margin-left: 15px;
}

.desktop-nav ul li a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 6px;
  transition: all 0.3s ease;
  background: linear-gradient(120deg, transparent 50%,#ffffff 20%);
  background-size: 220%;
  background-position: 100%;
}

.desktop-nav ul li a:hover {
  background-position: 0;
  box-shadow: 0 0 10px rgba(255, 140, 0, 0.5);
}

/* Mobile Navigation */
.mobile-nav {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #fff;
  width: 80%;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: pointer;
}

.mobile-nav.active {
  display: flex;
}

.mobile-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.mobile-nav ul li {
  margin: 10px 0;
}

.mobile-nav ul li a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  padding: 10px 20px;
  display: block;
}

/* Social Media Section Styles */
.social-media {
  display: flex;
  justify-content: flex-end; /* Align icons to the right */
  margin-top: 4px; /* Equivalent to mt-4 */
  width: 100%; /* Ensure it spans the width of the header */
  padding-right: 20px; /* Optional: Align with header padding */
  position: fixed;
  top: 14px;
  right: 1350px;
}

/* Individual Social Icon Styling */
.social-media a {
  margin: 0 16px; /* Equivalent to mx-2 */
  transition: color 0.3s ease;
  
}


@media (min-width: 769px) {
  .mobile-bottom-nav {
    display: none; /* Hide on desktop */
  }
}
/* Media Queries */
@media (max-width: 768px) {
  .desktop-nav {
    display: none; /* Hide desktop nav */
  }

  .social-media {
    display: flex;
    justify-content: flex-end; /* Align icons to the right */
    margin-top: 4px; /* Equivalent to mt-4 */
    width: 100%; /* Ensure it spans the width of the header */
    padding-right: 20px; /* Optional: Align with header padding */
    position: fixed;
    top: 85%;
    right: 30px;
  }

  .social-media .instagram-icon {
    display: none; /* Hide desktop nav */
  }

  .social-media .whatsapp-icon {
    display: none;
  
  }

  .mobile-bottom-nav {
    display: flex;
    position: fixed;
    bottom: -5px;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: 12px 10px;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ddd;
    z-index: 1000;
  }

  .mobile-bottom-nav ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  

  .mobile-bottom-nav ul li a {
    color: #000;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .mobile-bottom-nav ul li a:hover {
    color: #000000;
  }
}

/* Sidebar for Mobile Only */
.mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Background overlay */
  z-index: 1100;
  display: flex;
  justify-content: flex-end;
}

.sidebar-content {
  width: 70%;
  max-width: 80%;
  height: 100%;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.sidebar-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-content ul li {
  margin: 10px 0;
}

.sidebar-content ul li a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.sidebar-content ul li a:hover {
  color: #ff7b00;
}